.App {
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.spinner:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 0;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff #fff;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
